<template>
  <info-modal-template
    name="skin-type-info-modal"
    title="preliminaryResults.label.skinType"
    details="preliminaryResults.tooltip.skinType"
    @close="onCloseHandler"
    @before-open="onBeforeOpen"
  >
    <template #prepend>
      <div class="skin-type-info-images flex--align-center">
        <div
          v-for="{ image, text } in $options.skinTypeImages"
          :key="text"
          class="skin-type-info-images__image-container flex-column--align-center"
        >
          <img class="skin-type-info-images__image" :src="image" :alt="text" />
          <p class="skin-type-info-images__caption u-typography-helvetica u-text u-text--xs">
            <b>{{ $t(text) }}</b>
          </p>
        </div>
      </div>
    </template>
  </info-modal-template>
</template>

<script>
import InfoModalTemplate from '@/components/modals/templates/InfoModalTemplate';

import normalSkinTypeImage from '@/modules/questionnaire/assets/images/preliminary-results/skin-type/normal.svg';
import drySkinTypeImage from '@/modules/questionnaire/assets/images/preliminary-results/skin-type/dry.svg';
import oilySkinTypeImage from '@/modules/questionnaire/assets/images/preliminary-results/skin-type/oily.svg';
import combinationSkinTypeImage from '@/modules/questionnaire/assets/images/preliminary-results/skin-type/combination.svg';

const SKIN_TYPE_IMAGES = [
  { image: normalSkinTypeImage, text: 'preliminaryResults.skinType.normal' },
  { image: drySkinTypeImage, text: 'preliminaryResults.skinType.dry' },
  { image: oilySkinTypeImage, text: 'preliminaryResults.skinType.oily' },
  { image: combinationSkinTypeImage, text: 'preliminaryResults.skinType.combination' }
];

export default {
  name: 'SkinTypeInfoModal',
  components: { InfoModalTemplate },
  skinTypeImages: SKIN_TYPE_IMAGES,
  data() {
    return {
      onClose: null
    };
  },
  methods: {
    onBeforeOpen({ params: { onClose } }) {
      this.onClose = onClose;
    },
    onCloseHandler() {
      this.onClose();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~universkin-shared/src/assets/styles/scss/sizes';

.skin-type-info-images {
  flex-wrap: wrap;

  &__image-container {
    margin-top: 16px;

    &:not(:last-child) {
      margin-right: 14px;
    }
  }

  &__image {
    width: 45px;
  }

  &__caption {
    margin: 8px 0 0;
    color: var(--u-color-grey-900);
  }
}

@media (min-width: $tablet-start) {
  .skin-type-info-images {
    &__image-container {
      margin-right: 24px;
    }

    &__image {
      width: 48px;
    }
  }
}
</style>
